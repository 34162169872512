<template>
    <div>
        <button type="button" class="btn btn-info text-white d-block w-25 mx-auto"
                data-bs-toggle="modal" @click="getStaffDueSalary()"
                data-bs-target="#staticBackdrop">
            Salary
        </button>
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
             tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>Staff salary info</h4>
                        <button type="button" id="close-modal-salary" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="save">
                            <div class="mb-3 row">
                                <label class="col-2 col-form-label">Date</label>
                                <div class="col-10 mb-3">
                                    <date-picker @change="getStaffDueSalary" value-type="YYYY-MM-DD"
                                                 v-model="form.transaction_date" format="DD-MMM-YYYY"
                                                 placeholder="Select Date"/>
                                </div>
                                <label for="paid" class="col-2 col-form-label">Paid</label>
                                <div class="col-10">
                                    <input required v-model="form.final_total" type="number" class="form-control mb-3"
                                           id="paid">
                                </div>
                                <label for="due" class="col-2 col-form-label">Due</label>
                                <div class="col-10">
                                    <input v-model="getDueSalary" readonly type="number" class="form-control mb-3"
                                           id="due">
                                </div>
                                <label for="note" class="col-2 col-form-label">Note</label>
                                <div class="col-10">
                                    <input v-model="form.note" type="text" class="form-control mb-3" id="note">
                                </div>
                                <div class="col-6 offset-3 text-center">
                                    <input type="submit" class="btn btn-primary w-100" value="Save">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker          from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import moment              from 'moment'

export default {
    name      : "SalaryCreate",
    components: {DatePicker},
    data      : () => ({
        form: {
            transaction_date    : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            transactionable_id  : '',
            transactionable_type: "App\\Models\\User",
            final_total         : 0,
            visit_fee           : 0,
            due_salary          : 0,
            note                : '',
            type                : 'salary',
            payment_status      : 'paid',
            transaction_payments: {
                amount: 0
            }
        }
    }),
    computed  : {
        getDueSalary() {
            return this.form.due_salary - this.form.final_total
        }
    },
    methods   : {
        getStaffDueSalary() {
            let params = {
                user_id         : this.$parent.form.id,
                transaction_date: this.form.transaction_date,
            };
            ApiService.get(`/dropdown/get-staff-due-salary`, {params: params}).then((res) => {
                this.form.due_salary = res.data;
            })
        },
        save() {
            console.log()
            this.form.transactionable_id          = this.$parent.form.id;
            this.form.sub_total                   = this.form.final_total;
            this.form.transaction_payments.amount = this.form.final_total;
            this.form.visit_fee                   = this.getDueSalary;
            this.form.transaction_date            = moment(this.form.transaction_date).format("YYYY-MM-DD") +' '+ moment(new Date()).format("HH:mm:ss");
            ApiService.post('/transactions', this.form).then((res) => {
                document.querySelectorAll("#close-modal-salary")[0].click();
                this.$parent.edit(res.data.data.transactionable_id);
                this.formReset();
                NotificationService.success(res.data.message);
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                transaction_date    : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                transactionable_id  : '',
                transactionable_type: "App\\Models\\User",
                final_total         : 0,
                visit_fee           : 0,
                due_salary          : 0,
                note                : '',
                type                : 'salary',
                payment_status      : 'paid',
                transaction_payments: {
                    amount: 0
                }
            }
        }
    }
}
</script>
