<template>
    <!-- Modal -->
    <div class="modal fade" id="passwordCreateModal" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="passwordUpdate()">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ type == 'dashboard' ? 'Change' : 'Create'}} Password</h5>
                        <button id="close-modal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="type !== 'staff_edit'" class="mb-3">
                            <label for="password" class="form-label">Current Password</label>
                            <input required :class="{ 'is-invalid': errors['current_password'] }"
                                   v-model="form.current_password"
                                   type="password" class="form-control" id="password"
                                   placeholder="Current Password">
                            <div v-if="errors['current_password']" class="invalid-feedback">
                                {{ errors['current_password'][0] }}
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="password2" class="form-label">New Password</label>
                            <input required :class="{ 'is-invalid': errors['password'] }" v-model="form.password"
                                   type="password"
                                   class="form-control" id="password2"
                                   placeholder="New Password">
                            <div v-if="errors['password']" class="invalid-feedback">
                                {{ errors['password'][0] }}
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="password3" class="form-label">Confirm Password</label>
                            <input required :class="{ 'is-invalid': errors['confirm_password'] }"
                                   v-model="form.confirm_password"
                                   type="password" class="form-control" id="password3"
                                   placeholder="Conform Password">
                            <div v-if="errors['confirm_password']" class="invalid-feedback">
                                {{ errors['confirm_password'][0] }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";

export default {
    name   : "PasswordModal",
    props  : {
        user_id: {
            default: '',
        },
        type   : {
            default: '',
        },
    },
    data   : () => ({
        form  : {
            current_password: '',
            password        : '',
            confirm_password: '',
        },
        errors: [],
    }),
    methods: {
        passwordUpdate() {
            this.errors       = []
            this.form.user_id = this.user_id;
            this.form.type    = this.type;
            ApiService.post('/password-update', this.form).then(res => {
                if (res.data.status == 'success') {
                    this.formReset();
                    this.errors = []
                    NotificationService.success(res.data.message);
                    document.querySelectorAll("#close-modal")[0].click();
                } else {
                    NotificationService.error(res.data.message);
                }
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                current_password: '',
                password        : '',
                confirm_password: '',
                user_id         : '',
            }
        }
    }
}
</script>

<style scoped>

</style>
